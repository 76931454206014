import React, {useState} from "react";
import {Input, Text, Button, Stack, Modal, Center, Card} from "@mantine/core";
import {getUser, signIn, signUp} from "./ApiCalls";
import {showNotification} from "@mantine/notifications";
import {IconAlien, IconDisabled2} from "@tabler/icons";

const Auth = () =>{

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [opened, setOpened] = useState(false);

    const signUpCall = () =>{
        signUp(email,password).then((result) =>{
            // @ts-ignore
            console.log("inside then after signing Up"+result)
            setOpened(false)
            showNotification({
                title: 'SignUp Successful ',
                message: "Can you really win this?🤥",
                disallowClose: true,
                color: 'green',
                icon: <IconDisabled2 />,
                autoClose: 6000,
            })
        } )
    }

    const signInCall = () =>{
        signIn(email,password).then((result) =>{
            // @ts-ignore
            getUser().then((user)=>{
                // @ts-ignore
                const userId = user.id;
                // @ts-ignore
                sessionStorage.setItem("currentUserId", userId);
                // @ts-ignore
                showNotification({
                    title: 'Login Successful',
                    message: "Can you really win this?🤥",
                    disallowClose: true,
                    color: 'green',
                    icon: <IconDisabled2 />,
                    autoClose: 6000,
                });
                setTimeout(() =>{
                    //@ts-ignore
                    window.location.reload(false);
                },500);
                // @ts-ignore
                //console.log(userId.toString());
                // @ts-ignore
                //console.log("session storage " +sessionStorage.getItem("currentUserId"));
            })
        }).catch(() => {
            showNotification({
                title: 'Login error',
                message: "Please get your memory straight, bitch.net",
                disallowClose: false,
                color: 'red',
                icon: <IconAlien />,
                autoClose: 6000,
            });
        });
    }


    return(
        <div>
            <h1>You think you can win?</h1>
                <Input
                    placeholder="email"
                    // @ts-ignore
                    onChange={(e) =>{setEmail(e.target.value)}}
                />
                <Input
                    style={{marginTop:"3%"}}
                    placeholder="password"
                    // @ts-ignore
                    onChange={(e) =>{setPassword(e.target.value)}}
                />
            <Stack align="center" style={{marginTop:"3%"}}>
                <Text component="a" underline color={"#1971C2"} onClick={() => setOpened(true)} style={{cursor: "pointer"}}>
                    Create your stupid account here
                </Text>
                <Button onClick={() => {signInCall()}} >
                    Login
                </Button>
            </Stack>

            <Modal
                centered
                opened={opened}
                onClose={() => setOpened(false)}
                title='"Scavenger hunts don’t need to be competitive" cit. That is what weak people say!'
                //Two research scientists matched on the global dating app in the most remote part of the world - a man working at the United States Antarctic McMurdo Station and a woman camping a 45-minute helicopter ride away. What are the chances?!
            >
                {
                    <div>
                        <Input.Wrapper label="Email" required>
                            <Input
                                placeholder="email"
                                // @ts-ignore
                                onChange={(e) =>{setEmail(e.target.value)}}
                            />
                        </Input.Wrapper>

                        <Input.Wrapper label="Password" required style={{marginTop:"3%"}}>
                            <Input
                                placeholder="password"
                                // @ts-ignore
                                onChange={(e) =>{setPassword(e.target.value)}}
                            />
                        </Input.Wrapper>
                        <Center>
                            <Button style={{marginTop: "5%"}} onClick={() => {signUpCall()}}>
                                SignUp
                            </Button>
                        </Center>
                    </div>
                }
            </Modal>
        </div>
    );
}

export default Auth;