import React, {useState} from "react";
import {
    Card,
    Center,
    RingProgress,
    Input,
    Modal,
    Button
} from '@mantine/core';
import {IconScan, IconDisabled2, IconCheck, IconError404, IconAlien} from "@tabler/icons";
import {showNotification, updateNotification} from "@mantine/notifications";
import {getCode, updateCode} from "./ApiCalls";

// @ts-ignore
const TeamInfo = (props) =>{

    const [opened, setOpened] = useState(false);
    const [insertedCode, setInsertedCode] = useState("");
    const handleInsertedCodeChange = (e: any) => {
        setInsertedCode(e.target.value);
    };

    function setColor(){
        if(props.teamPoints < 4){
            return "red"
        }
        else if(props.teamPoints < 8){
            return "yellow"
        }
        else{
            return "green"
        }
    }

    const checkCode = () =>{
        showNotification({
            id: 'load-data',
            loading: true,
            title: 'Checking your Code',
            message: 'The checking process will take 2 seconds, don-t close this window',
            autoClose: false,
            disallowClose: true,
        });
        console.log(sessionStorage.getItem("currentUserId"));
        //let cleanCode = insertedCode.replaceAll('-','')
        //console.log(cleanCode)
        getCode(insertedCode)
            .then((code) => {
                console.log("code extracted"+code.code);
                updateCode(code).then(() => {
                    setTimeout(() => {
                        updateNotification({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Code approved',
                            message: 'The points have been added to your team',
                            icon: <IconCheck size={16} />,
                            autoClose: 2000,
                        });
                    }, 2000);

                    setTimeout(() =>{
                        //@ts-ignore
                        window.location.reload(false);
                    },3000);
                });
            }).catch(() => {
                setTimeout(() => {
                    updateNotification({
                        id: 'load-data',
                        color: 'red',
                        title: 'Code not approved',
                        message: 'You wish you had the points',
                        icon: <IconAlien size={16} />,
                        autoClose: 2000,
                    });
                }, 2000);
            });
        setOpened(false)
    }

    return(
        <div>
                <Card shadow="xl" p="lg" radius="md"  style={{fontSize: "150%"}}>
                    <Center>
                        <RingProgress
                            roundCaps={true}
                            size={240}
                            sections={[{ value: (props.usedPoints * 100) / 12, color: setColor() }]}
                            label={
                                <h1 color="blue">
                                    {props.teamPoints}
                                </h1>
                            }
                        />
                    </Center>
                    <Center>
                        <div>
                            <h1>{props.teamName}</h1>
                        </div>
                    </Center>

                    <Button size="md" variant="light" color="blue" fullWidth mt="md" radius="md" rightIcon={<IconScan size={18}/>} onClick={() => setOpened(true)}>
                        Insert Code
                    </Button>
                </Card>

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Insert Code"
            >
                {
                    <div>
                        <Input
                            placeholder="Code"
                            //component={InputMask} mask="****-****-****"
                            // @ts-ignore
                            onChange={(e) =>{setInsertedCode(e.target.value)}}
                        />
                        <Center>
                            <Button style={{marginTop: "5%"}} onClick={() => {checkCode()}}>
                                Submit Code
                            </Button>
                        </Center>
                    </div>
                }
            </Modal>

        </div>
    );
}

export default TeamInfo;