import React, {useEffect, useRef, useState} from "react";
import {supabase} from "./supabaseClient";
// @ts-ignore
import {IconScan, IconDisabled2, IconCheck} from "@tabler/icons";
import {Button, Center, Input, Modal, Table, Text, Group, ActionIcon, Stack} from "@mantine/core";
import InputMask from "react-input-mask";
import { createClient } from '@supabase/supabase-js'
import {createTeam, getCode, hasTeam, signUp, totalUsedCodes, updateCode} from "./ApiCalls";
import Account from "./Account";
import Auth from "./Auth";
import {showNotification, updateNotification} from "@mantine/notifications";
import { Skeleton } from '@mantine/core';
import TeamInfo from "./TeamInfo";



const MainPage = () => {
    const [opened, setOpened] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [teamPoints, setTeamPoints] = useState("")
    const [returnedCode, setReturnedCode] = useState("");
    const [insertedCode, setInsertedCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [usedCodes, setUsedCodes] = useState<number>(0);

    const fetchTeam = async () => {
        console.log(sessionStorage.getItem("currentUserId"))
        if(sessionStorage.getItem("currentUserId") !== null){
            hasTeam().then(async (count) =>{
                console.log(count)
                if (count !== 0){
                    const {data, error} = await supabase.from('teams').select().eq('userId',sessionStorage.getItem("currentUserId")).limit(1).single();
                    const { count } = await supabase.from('usedCodes').select('*', { count: 'exact', head: true }).match({ teamName: sessionStorage.getItem("currentTeamName")});

                    console.log(data);
                    setTeamName(data.TeamName);
                    setTeamPoints(data.Points);
                    sessionStorage.setItem("currentTeamName", data.TeamName);
                    sessionStorage.setItem("currentTeamPoints", data.Points);
                    console.log("current Team: "+ sessionStorage.getItem("currentTeamName"));
                    console.log("current Points: "+ sessionStorage.getItem("currentTeamPoints"));
                    // @ts-ignore
                    // sessionStorage.setItem("totalScannedCodes", count);
                    setUsedCodes(count);
                    /*totalUsedCodes().then((count: any) =>{
                        sessionStorage.setItem("totalScannedCodes", count);
                        console.log("totalScannedCodes: "+count);
                    })*/
                }
            })
        }
        //console.log(data)
    }

    useEffect(() => {
        fetchTeam()
    }, [])


    const createTeamCall = () =>{
        createTeam(teamName)
            .then(() =>{
                showNotification({
                    title: 'Yay',
                    message: "Team Created",
                    disallowClose: true,
                    color: 'green',
                    icon: <IconCheck />,
                    autoClose: 6000,
                })
                setTimeout(() =>{
                    //@ts-ignore
                    window.location.reload(false);
                },500);
                // @ts-ignore
                //window.location.reload(false);
            })
    }

    return (
        <div className="App">
            {
                sessionStorage.getItem("currentUserId") === null?
                    <Center style={{marginTop: "5%"}}>
                        <Auth/>
                    </Center>
                    :
                    <div>
                    {
                        sessionStorage.getItem("currentTeamName") !== null?

                            <div>
                                <Center style={{marginTop:"10%"}}>
                                    <TeamInfo
                                        teamName={teamName}
                                        teamPoints={teamPoints}
                                        usedPoints={usedCodes}
                                    />
                                </Center>
                            </div>
                            :
                            <Stack align="center" style={{marginTop:"3%"}}>
                                <Input
                                    placeholder="Team Name"
                                    // @ts-ignore
                                    onChange={(e) =>{setTeamName(e.target.value)}}
                                />
                                <Button onClick={() => {createTeamCall()}}>
                                    Create Team
                                </Button>
                            </Stack>
                    }

                    </div>
            }
        </div>
    );
}

export default MainPage