import React from "react";
import {supabase} from "./supabaseClient";
import {showNotification, updateNotification} from "@mantine/notifications";
// @ts-ignore
import {IconCheck, IconDisabled2, IconAlertCircle} from "@tabler/icons";

class Code{
    id: number = 0;
    points: number = 0;
    code: string = "";
}

class UsedCode{
    id: number = 0;
    team: string = "";
    code: string = "";
}

export function updateCode(cleanCode: Code){
    return new Promise(async(resolve,reject) => {
        updateTeamPoints(cleanCode).then(async () => {
            console.log("team points updated");
            updateUsedCodes(cleanCode).then(async () => {
                const returnedCode = await supabase.from('codes').update({points: cleanCode.points - 1}).eq("code", cleanCode.code);
                if (returnedCode.error !== null) {
                    return reject(returnedCode.error);
                }
                return resolve(returnedCode.data);
            })
        })
    })
}

export function getCode(cleanCode: string): Promise<Code>{
    return new Promise<Code>(async(resolve,reject) =>{
        console.log(cleanCode + " cleanCode inside getCode");
        const returnedCode = await supabase.from('codes').select().eq('code',cleanCode).limit(1).single();
        console.log(returnedCode.data + " returnedCode.data");
        if (returnedCode.error !== null){
            return reject(returnedCode.error);
        }
        checkIfUsed(cleanCode).then((code) =>{
            //console.log("code"+code.code);
            /*if(code.code === null){

            }
            return reject("Code already used");
             */
            return resolve(returnedCode.data);
        });
    })
}

export function updateTeamPoints(code: Code){
    return new Promise(async(resolve,reject) =>{
        console.log("team points: "+sessionStorage.getItem("currentTeamPoints"));
        const loggedTeam = await supabase.from('teams').update({Points: Number(sessionStorage.getItem("currentTeamPoints")) + code.points}).eq("TeamName",sessionStorage.getItem("currentTeamName"));
        if (loggedTeam.error !== null){
            console.log("team points error");
            return reject(loggedTeam.error);
        }
        return resolve(loggedTeam.data);
    })
}

export function updateUsedCodes(cleanCode: Code){
    return new Promise(async (resolve,reject) => {
        const teamCodes = await supabase.from('usedCodes').insert({ teamName: sessionStorage.getItem("currentTeamName"), code: cleanCode.code})
        if (teamCodes.error !== null){
            console.log("team points error");
            return reject(teamCodes.error);
        }
        return resolve(teamCodes.data);
    })
}

export function hasTeam(){
    return new Promise(async (resolve, reject) =>{
        const { count, error } = await supabase.from('teams').select('*', { count: 'exact', head: true }).eq('userId',sessionStorage.getItem("currentUserId"));
        if (error !== null){
            reject(setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'red',
                    title: "Fucking Error",
                    message: error.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                });
            }, 2000));
        }
        resolve(count);
    })
}

export function createTeam(teamName: string){
    return new Promise(async(resolve, reject) =>{
        const { error } = await supabase
            .from('teams')
            .insert({TeamName: teamName, Points: 0, userId: sessionStorage.getItem("currentUserId")});
        if (error !== null){
            reject(setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'red',
                    title: "Fucking Error",
                    message: error.message,
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                });
            }, 2000));
        }
        resolve((sessionStorage.setItem("currentTeamName", teamName) , sessionStorage.setItem("currentTeamPoints", "0")))
    })
}

export function checkIfUsed(cleanCode: string){
    return new Promise(async(resolve,reject) => {
        /*const used = await supabase.from('usedCodes').select().eq('code',cleanCode).single();
        //const used = await supabase.from('usedCodes').select().contains('code', cleanCode);
        console.log("used: "+used.data);
        if(used.error?.code === "PGRST116"){
            console.log("Checkig if code works");
            return resolve(used.data);
        }
        if (used.error !== null){
            console.log("team points error");
            return reject(used.error);
        }
        return reject("code already used");*/
        const { count, error } = await supabase.from('usedCodes').select('*', { count: 'exact', head: true }).match({ teamName: sessionStorage.getItem("currentTeamName"), code: cleanCode });
        // @ts-ignore
        if (count > 0 || error){

            // @ts-ignore
            reject(setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'red',
                    title: 'Code already used',
                    message: "Don't try to cheat",
                    icon: <IconCheck size={16} />,
                    autoClose: 2000,
                });
            }, 2000));
        }
        resolve(console.log("number of time this code has been used: "+count))
    })
}

export function signUp(email: string, password: string){
    return new Promise(async (resolve, reject) =>{
        console.log("email"+ email +"  Password: "+ password)
        const {data, error } = await supabase.auth.signUp({
            email: email,
            password: password,
        })
        if (error !== null){
            reject(error.message);
        }
        //supabase.auth.
        resolve(console.log("Successfully registered"))
    })
}

export function signIn(email: string, password:string){
    return new Promise(async (resolve, reject) =>{
        const { data, error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password,
        })
        if (error){
            console.log("inside error");
            reject(error.message);
        }
        resolve(console.log("after login: "+data));
    })
}

export function getUser(){
    return new Promise(async (resolve, reject) =>{
        const {data: {user} } = await supabase.auth.getUser()
        resolve(user);
    });
}

export function totalUsedCodes(){
    return new Promise(async (resolve, reject) =>{
        const { count, error } = await supabase.from('usedCodes').select('*', { count: 'exact', head: true }).match({ teamName: sessionStorage.getItem("currentTeamName")});

        if(error){
            reject(console.log("something went wrong in the count"));
        }
        resolve(count);
    })
}
