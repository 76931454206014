import React from 'react';
import './App.css';
import MainPage from "./MainPage";
import {NotificationsProvider} from "@mantine/notifications";

function App() {


  return (
    <div>
        <NotificationsProvider position="top-right">
            <MainPage/>
        </NotificationsProvider>
    </div>
  );
}

//WRCpA7@utYvfWZK SupaBase DB password

export default App;
